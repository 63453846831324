import React from "react"

import JSONData from "../../../content/Emails.json"
import TextList from "../../components/organisms/TextList"
import BaseTemplate from "../../components/templates/BaseTemplate"

const processRawData = rawData => {
  const parseDate = strDate => {
    const dateTokens = strDate.split(".")
    const year = parseInt(dateTokens[0])
    const month = parseInt(dateTokens[1])
    const date = parseInt(dateTokens[2])
    return `${year}년 ${month}월 ${date}일`
  }

  return {
    emails: rawData.map(data => {
      return {
        date: parseDate(data.date),
        titlePrefix: data.chapterNumber > 0 ? `제${data.chapterNumber}장 ` : "",
        title: data.title,
        link: data.link,
      }
    }),
    lastDate: parseDate(rawData[rawData.length - 1].date),
  }
}

const EmailListPage = () => {
  const { emails, lastDate } = processRawData(JSONData)

  return (
    <BaseTemplate title={"도냐 키히데와 건초 판사의 비망록"}>
      <div style={{ textAlign: `center` }}>
        <h3>도냐 키히데와 건초 판사의 비망록</h3>
        <h4>지금까지의 모험</h4>
        <br />
        <br />
      </div>
      <TextList rows={emails} footer={<p>({lastDate}까지 발송 분)</p>} />
      <br />
      <p>
        * 매주 화, 수, 금 발송하고 있으며 목차에는 최신 발송 분이 반영되지 않을
        수 있습니다
      </p>
    </BaseTemplate>
  )
}

export default EmailListPage
