import React from "react"

const ListItem = ({ titlePrefix, title, link }) => (
  <li>
    {link ? (
      <a href={link} style={{ color: `black` }}>
        {titlePrefix}
        {title}
      </a>
    ) : (
      <>
        {titlePrefix}
        {title}
      </>
    )}
  </li>
)

const TextList = ({ rows, header, footer }) => (
  <>
    <ul style={{ listStyleType: `none`, margin: 0, padding: 0 }}>
      {rows.map(({ titlePrefix, title, link }, index) => {
        return (
          <ListItem
            key={`content_item_${index}`}
            titlePrefix={titlePrefix}
            title={title}
            link={link}
          />
        )
      })}
    </ul>
    {footer}
  </>
)

export default TextList
